import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { TestCaseList } from "src/app/main/testcase/content/TestCaseList";
import { setTestsLoading } from "src/app/main/testcase/store/testsSlice";
import {
  getTestRunTestsBySuite,
  openTestRightSidebar,
  selectTest,
  selectTestRunTestPaginate,
  selectTestRunTests,
  setSelectedTest,
} from "../../store/testRunProcessSlice";
import { setPageCount } from "app/store/paginationSlice";

const TestRunResultContent = () => {
  const testCases = useSelector(selectTestRunTests);
  const testCasesPaginate = useSelector(selectTestRunTestPaginate);
  const dispatch = useDispatch();
  const { testRunKey } = useParams();
  const [totalCount, setTotalCount] = useState(testCasesPaginate.total_count);
  const selectedTest = useSelector(selectTest);

  const onSuiteOrPaginationChange = (suiteId, page, filters) => {
    dispatch(setSelectedTest({}))
    if (!suiteId) return;

    dispatch(

      getTestRunTestsBySuite({
        testRunKey,
        suiteId,
        pagination: { page, size: 20 },
      })
    ).then((res) => {
      const paginate = res.payload.paginate;
      dispatch(setPageCount(paginate.total_pages));
      dispatch(setTestsLoading(false));
    });
  };

  const onTestClick = (test) => {
    dispatch(setSelectedTest(test));
    dispatch(openTestRightSidebar(true));
  }

  return (
    <TestCaseList
      selected={selectedTest?.code}
      onTestClick={onTestClick}
      statusIndicator={false}
      totalPages={totalCount}
      onChange={onSuiteOrPaginationChange}
      tests={testCases}
      showStatus={true}
      showSubTitle={false}
      actionButtons={false}
      multiple={false}
    />
  );
};

export default TestRunResultContent;
