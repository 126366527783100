import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import {
  Button,
  DialogContentText,
  DialogTitle,
  ListItem,
  ListItemText,
  Typography,
} from "@mui/material";
import FuseSvgIcon from "@fuse/core/FuseSvgIcon";
import { closeDialog, openDialog } from "app/store/fuse/dialogSlice";
import { useDispatch } from "react-redux";
import { List } from "immutable";

const SystemDialog = ({ messages = [], message = "" }) => {
  const dispatch = useDispatch();
  return (
    <>
      <DialogTitle
        className="flex flex-row items-center"
        id="alert-dialog-title"
      >
        <FuseSvgIcon size={40} className=" text-orange-400 mr-20">
          feather:alert-triangle
        </FuseSvgIcon>
        System Notification
      </DialogTitle>
      <DialogContent>
        {messages.map((msg) => (
          <Typography>{msg}</Typography>
        ))}

        {message}
      </DialogContent>

      <DialogActions className="flex flex-auto text-right px-20 py-20 bg-gray-50 ">
        <Button
          variant="contained"
          onClick={() => dispatch(closeDialog())}
          color="secondary"
        >
          Ok
        </Button>
      </DialogActions>
    </>
  );
};

export default SystemDialog;
