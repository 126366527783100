import { Typography } from "@mui/material";
import { StatusBadge } from "app/shared-components/StatusBadge";
import { useSelector, useDispatch } from "react-redux";
import { selectTestCaseTypes } from "../../../store/testSlice";
import { styled } from "@mui/material/styles";
import PriorityIcon from "../../../PriorityIcon";
import TestResults from "./TestResults";
import { SplitterSection } from "./SplitterSection";
import { dateFormatter } from "../../../../../utils/Formatter";
import TestTags from "./TestTags";
import LinkedIssues from "./LinkedIssues";

const StyledTypography = styled(Typography)(({ theme }) => ({
  "&.MuiTypography-root .editor p": {
    marginTop: 14,
    marginBotton: 14,
  },
  "&.MuiTypography-root .editor ul": {
    listStyleType: "disc",
    paddingLeft: 25,
  },
  "&.MuiTypography-root .editor ol": {
    listStyleType: "list-decimal",
    paddingLeft: 25,
  },
}));

export const TestFormView = ({ test, isRightSide = false }) => {
  const testcaseTypes = useSelector(selectTestCaseTypes);

  const isEditorNotEmpty = (field) => {
    return field == "<p></p>" || field == null ? false : true;
  };

  return (
    <div className="flex w-full flex-col gap-10 px-36 pt-24">
      <div className="mb-24">
        {/* <Typography className="text-gray-700 font-semibold mb-5 text-15">
            Summary
          </Typography> */}
        <Typography className="mb-10" variant="h6">
          {test?.summary}
        </Typography>
        <div className="flex flex-row gap-4">
          {test.priority && (
            <PriorityIcon
              title={test.priority?.name}
              icon={test.priority?.icon}
              color={test.priority?.color}
            />
          )}

          {/* <StatusBadge status={test?.status}></StatusBadge> */}
          <StatusBadge status={test?.automation_status}></StatusBadge>
        </div>
      </div>

      {/* TAGS SESSION */}
      {test.tags.length > 0 && (
        <div className="mb-10">
          <TestTags tags={test.tags} />
        </div>
      )}

      {/* LINKED ISSUES */}
      {test.linked_issues.length > 0 && (
        <div className="mb-24">
          <LinkedIssues linkedIssues={test.linked_issues} />
        </div>
      )}

      {testcaseTypes.find((item) => item.id == test?.template_id)?.name ==
        "Steps" ? (
        <>
          <div className={`flex w-full gap-10 ${isRightSide ? "flex-col" : "flex-row"}`}>
            {isEditorNotEmpty(test?.description) && (
              <div className={`mb-24 ${isRightSide ? "" : "w-1/2"}`} >
                <StyledTypography className=" font-semibold mb-5 text-15">
                  Description
                </StyledTypography>
                <Typography>
                  <div
                    dangerouslySetInnerHTML={{ __html: test?.description }}
                  />
                </Typography>
              </div>
            )}
            {isEditorNotEmpty(test?.pre_conditions) && (
              <div className={`mb-24 ${isRightSide ? "" : "w-1/2"}`}>
                <Typography className="  font-semibold mb-5 text-15">
                  Pre-Conditions
                </Typography>
                <styledTypography>
                  <div
                    dangerouslySetInnerHTML={{ __html: test?.pre_conditions }}
                  />
                </styledTypography>
              </div>
            )}
          </div>
          <div className={`flex w-full gap-10 ${isRightSide ? "flex-col" : "flex-row"}`}>
            {isEditorNotEmpty(test?.steps) && (
              <div className={`mb-24 ${isRightSide ? "" : "w-1/2"}`}>
                <Typography className=" font-semibold mb-5 text-15">
                  Steps
                </Typography>
                <Typography>
                  <div dangerouslySetInnerHTML={{ __html: test?.steps }} />
                </Typography>
              </div>
            )}

            {isEditorNotEmpty(test?.expected_results) && (
              <div className={`mb-24 ${isRightSide ? "" : "w-1/2"}`}>
                <Typography className="  font-semibold mb-5 text-15">
                  Expected Results
                </Typography>
                <Typography>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: test?.expected_results,
                    }}
                  />
                </Typography>
              </div>
            )}
          </div>
        </>
      ) : (
        <>
          {isEditorNotEmpty(test?.description) && (
            <div className="mb-24">
              <Typography className="  font-semibold mb-5 text-15">
                Description
              </Typography>
              <StyledTypography>
                <div
                  className="editor max-h-512  overflow-scroll"
                  dangerouslySetInnerHTML={{ __html: test.description }}
                />
              </StyledTypography>
            </div>
          )}
        </>
      )}
      <div className="flex flex-col w-full  gap-20 items-end">

        {test?.splitters.length > 0 && (
          <div className="flex w-full flex-col grow">
            <SplitterSection splitters={test.splitters} />
          </div>
        )}


        <div className="flex flex-col w-full items-end justify-end">
          <Typography color="text.secondary" className="text-12 tracking-tight">
            Created by {test.created_by_name}, {dateFormatter(test.created_at)}
          </Typography>
          <Typography
            color="text.secondary"
            className=" text-12  tracking-tight"
          >
            Updated by {test.updated_by_name}, {dateFormatter(test.updated_at)}
          </Typography>
        </div>
      </div>

      <TestResults testId={test.id} />
    </div>
  );
};
