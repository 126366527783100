import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import {
  Button,
  DialogContentText,
  DialogTitle,
  ListItem,
  ListItemText,
  Typography,
} from "@mui/material";
import FuseSvgIcon from "@fuse/core/FuseSvgIcon";
import {
  closeDialog,
  openDialog,
  selectDialogLoading,
} from "app/store/fuse/dialogSlice";
import { useDispatch } from "react-redux";
import { List } from "immutable";
import { useSelector } from "react-redux";
import { LoadingButton } from "@mui/lab";

const ConfirmDialog = (props) => {
  const {
    title = "System Message",
    message,
    okButton,
    confirmButton,
    confirmButtonLabel = "Confirm",
    confirmAction,
    cancelButton = true,
    confirmButtonColor = "primary",
    cancelButtonLabel = "Cancel",
  } = props;
  const dispatch = useDispatch();
  const loading = useSelector(selectDialogLoading);
  return (
    <>
      <DialogTitle
        className="flex flex-row items-center"
        id="alert-dialog-title"
      >
        <FuseSvgIcon size={40} className=" text-orange-400 mr-20">
          feather:alert-triangle
        </FuseSvgIcon>
        {title}
      </DialogTitle>
      <DialogContent>{message}</DialogContent>

      <DialogActions className="flex  flex-auto text-right px-20 py-20 dark:bg-[#111827]  bg-gray-50 ">
        {cancelButton && (
          <Button
            disabled={loading}
            onClick={() => dispatch(closeDialog(true))}
          >
            {cancelButtonLabel}
          </Button>
        )}
        {okButton && <Button>OK</Button>}
        {confirmButton && (
          <LoadingButton
            variant="contained"
            onClick={confirmAction}
            color={confirmButtonColor}
          >
            {confirmButtonLabel}
          </LoadingButton>
        )}
      </DialogActions>
    </>
  );
};

export default ConfirmDialog;
