import { FormHelperText, TextField } from "@mui/material";
import React from "react";
import Autocomplete, { createFilterOptions } from "@mui/material/Autocomplete";

const filter = createFilterOptions();
export const AutocompleteTag = ({
  helpText,
  label = "Tags",
  value,
  onChange,
  options,
  className = "",
  create = true,
}) => {
  const [values, setValue] = React.useState(value);


  return (
    <>
      <Autocomplete
        value={values}
        disablePortal
        multiple
        onChange={(event, newValue) => {
          const lastIndex = newValue.length - 1;
          if (lastIndex != -1) {
            newValue[lastIndex] = newValue[lastIndex].replace("Add ", "");
          }
          setValue(newValue);
          onChange(event, newValue);
        }}
        filterOptions={(options, params) => {
          const filtered = filter(options, params);
          const { inputValue } = params;
          // Suggest the creation of a new value
          const isExisting = options.some((option) => inputValue === option);
          const isExistings = values?.includes(inputValue);

          if (inputValue !== "" && !isExisting && !isExistings && create) {
            filtered.push(`Add ${inputValue}`);
          }
          return filtered;
        }}
        selectOnFocus
        clearOnBlur
        isOptionEqualToValue={(option, value) => option === value}
        size="small"
        className={className}
        handleHomeEndKeys
        id="free-solo-with-text-demo"
        options={options}
        renderOption={(props, option) => <li {...props}>{option}</li>}
        freeSolo
        renderInput={(params) => <TextField {...params} label={label} />}
      />
      {helpText && <FormHelperText id="help-text">{helpText}</FormHelperText>}
    </>




  );
};
