import { Chip, Link, Tooltip, tooltipClasses, Typography } from '@mui/material'
import { StatusBadge } from 'app/shared-components/StatusBadge'
import React from 'react'
import BugReportIcon from '@mui/icons-material/BugReport';
import FuseSvgIcon from '@fuse/core/FuseSvgIcon';
import { styled } from '@mui/material/styles';

const HtmlTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: '#f5f5f9',
        color: 'rgba(0, 0, 0, 0.87)',
        maxWidth: 320,
        fontSize: theme.typography.pxToRem(12),
        border: '1px solid #dadde9',
    },
}));

const LinkedIssues = ({ linkedIssues }) => {
    return (
        <div>
            <Typography className=" text-gray-700 font-semibold mb-5 text-13">
                Related Issues
            </Typography>
            <div className="flex flex-row flex-wrap gap-4">
                {linkedIssues.map((issue) => (

                    <HtmlTooltip key={issue.key} title={
                        <React.Fragment>
                            <Typography color="inherit">{`${issue.key} ${issue.summary}`}</Typography>
                            <br></br>
                            <div><b>{'Type: '}</b> {issue.issue_type.name}</div>
                            <div><b>{'Status: '}</b> {issue.status}</div>

                        </React.Fragment>
                    }>
                        <Link role='button' variant="button" href={issue.url} target='_blank'>
                            <span className='flex gap-5 cursor-pointer hover:bg-grey-100 items-center border-1 text-12 font-500 text-blue-700 rounded-4 border-gray-400 px-9 py-3'>
                                <FuseSvgIcon className={`text-${issue.issue_type.color}-800`} size={20} >{issue.issue_type.icon}</FuseSvgIcon>
                                {issue.key}
                            </span>
                        </Link>
                    </HtmlTooltip>

                ))}
            </div>
        </div>
    )
}

export default LinkedIssues