import * as React from 'react';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import FuseSvgIcon from '@fuse/core/FuseSvgIcon';
import Button from '@mui/material/Button';
import _ from '@lodash';
import clsx from 'clsx';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { useState } from 'react';
import { updateTest } from '../store/testSlice';
import useRoleBasedAccess from 'src/app/utils/customHook';
import { authRoles } from 'src/app/auth';
import { getSuiteMetrics } from '../store/suiteSlice';
import { selectTests, updateStatusByTestId } from '../store/testsSlice';
import useEnhancedEffect from '@mui/utils/useEnhancedEffect';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { addTestResult, nextTest, updateExecutionStatus, updateTestExecutionStatus } from '../../testrun/store/testRunProcessSlice';
import { openDialog } from 'app/store/fuse/dialogSlice';
import TestResultDialog from '../../testrun/testrun-process/TestResultDialog';
import { RESPONSE } from 'src/app/utils/types';

export const TestCaseStatusSelector = ({ test, value, onChange, addResult = true }) => {
  const dispatch = useDispatch();
  const { testStatus = [] } = useSelector(state => state.suitesApp.tests);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [selectedOption, setselectedOption] = useState(value);
  const open = Boolean(anchorEl);
  const { hasRequiredRole } = useRoleBasedAccess(authRoles.external_tester, []);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  useEffect(() => {
    setselectedOption(value)
  }, [value])


  const handleSelect = (id, event) => {
    const statusId = id;

    if (selectedOption.id == id)
      return;

    const newStatus = _.find(testStatus, { id })
    onChange(newStatus);

    if (addResult) {

      // dispatch(openDialog({
      //   children: (
      //     <TestResultDialog
      //       title={`Add Result - ${test.code}`}
      //       executionId={test.execution_id}
      //       status={newStatus}
      //     />),
      // }));

     

      const data = {
        execution_id: test.testexecution_id,
        status_id: newStatus.id
    }
   
      dispatch(addTestResult(data)).then((res) => {
        if (res.payload.status === RESPONSE.SUCCESS) {
            dispatch(updateExecutionStatus({ id: test.id, newStatus }))
            // dispatch(nextTest());
        }
    });
    } else {
      setselectedOption(newStatus);
    }
    handleClose();

  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Button

        id="priority-button"
        aria-controls="priority-menu"
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={hasRequiredRole ? handleClick : undefined}
        className={clsx('w-[130px] px-12 rounded-4 py-0 h-28 min-h-28', `bg-${selectedOption?.color}-100  text-${selectedOption?.color}-800`)}

      >
        {/* <FuseSvgIcon className="mx-4" size={16}>
          {selectedOption.icon}
        </FuseSvgIcon> */}
        <div className="flex flex-row w-full items-center">
          <span className="w-full">{selectedOption.name}</span>
          {hasRequiredRole && <FuseSvgIcon className="flex-none  mx-4 text-end" size={16}>
            material-solid:arrow_drop_down
          </FuseSvgIcon>}
        </div>

      </Button>
      <Menu
        id="priority-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'priority-button',
        }}
      >
        {testStatus.map((item) => (
          <MenuItem onClick={(ev) => handleSelect(item.id, ev)} key={item.id}>
            <ListItemText primary={item.name} />
            <ListItemIcon className="min-w-40 justify-end" sx={{ color: item.color }}>
              {/* <FuseSvgIcon size={16}>{item.icon}</FuseSvgIcon> */}
            </ListItemIcon>
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};


