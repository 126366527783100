import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { addTestResult, nextTest, selectTest, selectTestRunTests, setSelectedTest, updateExecutionStatus } from '../../store/testRunProcessSlice';
import { TestFormView } from 'src/app/main/testcase/header/test-form/form-preview/TestFormView';
import { Button, Divider, IconButton, Typography } from '@mui/material';
import CloseIcon from "@mui/icons-material/Close";
import { useDispatch } from 'react-redux';
import { selectTestStatus } from 'src/app/main/testcase/store/testsSlice';
import { LoadingButton } from '@mui/lab';
import { RESPONSE, TEST_STATUS } from 'src/app/utils/types';
import { openDialog } from 'app/store/fuse/dialogSlice';
import TestResultDialog from '../TestResultDialog';


const TestRightSidebar = (props) => {
    const selectedTest = useSelector(selectTest);
    const dispatch = useDispatch();
    const testStatus = useSelector(selectTestStatus)
    const [loading, setLoading] = useState(false)
    const tests = useSelector(selectTestRunTests)
    let [isLastTest, setIsLastTest] = useState(false);
    let testIndex = -1;

    const onClose = () => {
        dispatch(setSelectedTest({}));
    }

    useEffect(() => {
        testIndex = tests.findIndex((item) => item.id === selectedTest.id);
        const isLastTest = (testIndex === tests.length - 1) ? true : false;
        setIsLastTest(isLastTest);
    }, [selectedTest])


    const onNextPass = () => {
        setLoading(true);
        const newStatus = testStatus.find((item) => item.name == TEST_STATUS.PASSED);
        const data = {
            execution_id: selectedTest.testexecution_id,
            status_id: newStatus.id
        }

        dispatch(addTestResult(data)).then((res) => {
            setLoading(false);
            if (res.payload.status === RESPONSE.SUCCESS) {
                dispatch(updateExecutionStatus({ id: selectedTest.id, newStatus }))
                dispatch(nextTest());
            }
        });
    }

    const openTestResultDialog = (second) => {
        dispatch(openDialog({
            children: (
                <TestResultDialog
                    title={`Add Result - ${selectedTest.code}`}
                    executionId={selectedTest.execution_id}
                    status={selectedTest.status}

                />),
        }));
    }

    return (
        <div className='flex flex-col'>
            <div className='sticky top-0 '>
                <div className='flex w-full justify-between dark:bg-[#1e293b] bg-white items-center  gap-20 px-36 p-10 '>
                    <Typography className='flex text-16 font-semibold' variant='h7'>{selectedTest?.code}</Typography>
                    <div className=" flex gap-5 grow pl-10 dark:bg-[#111827] bg-gray-50 rounded-32 items-center  justify-between">

                        <div className='flex gap-10'>
                            {/* <Button disabled={loading}
                                onClick={openTestResultDialog}
                                size='small' variant="text">Add result</Button> */}

                            <LoadingButton
                                loading={loading}
                                size='small'
                                color="secondary"
                                variant="outlined"
                                onClick={onNextPass}
                                disabled={isLastTest}
                            >
                                Pass & Next
                            </LoadingButton>

                        </div>

                        <IconButton disabled={loading} onClick={() => onClose()}>
                            <CloseIcon />
                        </IconButton>
                    </div>

                </div>

                <Divider />
            </div>


            {selectedTest?.code &&
                <div>
                    <TestFormView isRightSide={true} test={selectedTest} />
                </div>}
        </div>
    )
}

export default TestRightSidebar