import React from 'react'
import { styled } from "@mui/material/styles";
import {
    Dialog,
    Toolbar,
    AppBar,
    Typography,
    IconButton,
    DialogTitle,
    DialogContent,
} from "@mui/material";
import { useSelector } from 'react-redux';
import CloseIcon from "@mui/icons-material/Close";
import { closeDialog } from 'app/store/fuse/dialogSlice';
import { useDispatch } from 'react-redux';
import ResultForm from './results/ResultForm';


const CustomDialog = styled(Dialog)(({ theme }) => ({
    "& .MuiDialog-container": {
        alignItems: "normal",
    },
}));

const TestResultDialog = (props) => {
    const { title, executionId, status } = props;
    const dispatch = useDispatch();

    return (
        <div>
            <AppBar
                className="sticky top-0 z-[999]"
                color="secondary"
                position="static"
                elevation={0}
            >
                <Toolbar className="px-36 flex w-full justify-between">
                    <Typography variant="subtitle1" color="inherit">
                        {title}
                    </Typography>
                    <IconButton onClick={() => dispatch(closeDialog())} >
                        <CloseIcon className="text-white" />
                    </IconButton>
                </Toolbar>

            </AppBar>
            <DialogContent className='p-0'>
                <ResultForm executionId={executionId} status={status} />
            </DialogContent>
        </div >
    )
}

export default TestResultDialog