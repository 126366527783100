import React, { useEffect, useState } from 'react'
import { getTestResults, selectTestResults } from '../../../store/testSlice';
import { useDispatch } from 'react-redux';
import FuseLoading from '@fuse/core/FuseLoading';
import { Skeleton, Typography } from '@mui/material';
import { useSelector } from 'react-redux';
import ResultEmptyState from './ResultEmptyState';
import {
  TimelineConnector,
  TimelineContent,
  TimelineDot,
  TimelineSeparator,
  Timeline,
  TimelineItem,
} from "@mui/lab";
import { timelineItemClasses } from "@mui/lab/TimelineItem";
import { dateFormatter } from 'src/app/utils/Formatter';

const SkeletonLoading = () => {
  const array = [1, 2, 3, 4, 5];
  return (
    <>
      {
        array.map((item) => (
          <div key={item} className='flex flex-col mb-20'>
            <Skeleton width={210} height={20} animation="wave" />
            <Skeleton width={160} height={20} animation="wave" />
            <Skeleton width={110} height={20} animation="wave" />
          </div>
        )
        )
      }
    </>


  )

}

const TestResultTimeLine = ({ testId }) => {
  const dispatch = useDispatch();
  const testResults = useSelector(selectTestResults);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    dispatch(getTestResults(testId)).then(() => setLoading(false));
  }, [testId]);

  const setColor = (color) => ` text-${color}-800`;
  const setBgColor = (color) => `bg-${color}-800`;


  if (loading)
    return (<SkeletonLoading />)

  return (
    <div>
      {testResults.length == 0 ? (
        <ResultEmptyState className="mb-20" />
      ) : (
        <Timeline
          sx={{
            [`& .${timelineItemClasses.root}:before`]: {
              flex: 0,
              padding: 0,
            },
          }}
        >
          {testResults.map((item) => (
            <TimelineItem key={item.id}>
              <TimelineSeparator>
                <TimelineDot
                  className={`${setBgColor(item.status.color)}`}
                />
                <TimelineConnector />
              </TimelineSeparator>
              <TimelineContent>
                {
                  item.testrun && (<Typography className='text-grey-700'>
                    {item.testrun.key} |  {item.testrun.name}

                  </Typography>)
                }
                <Typography>
                  {item.created_by}
                </Typography>

                <Typography className="font-300 text-12">
                  {dateFormatter(item.updated_at)}

                </Typography>

                <Typography
                  className={`text-12 ${setColor(item.status.color)}`}
                >
                  {item.status.name}
                </Typography>

              </TimelineContent>
            </TimelineItem>

          ))
          }
        </Timeline>
      )}
    </div>


  )
}
export default TestResultTimeLine