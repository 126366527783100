import { IconButton, InputBase, Paper, Typography } from "@mui/material";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { styled } from "@mui/material/styles";
import clsx from "clsx";
import FuseSvgIcon from "@fuse/core/FuseSvgIcon";
import {
  addChatMessage,
  clearChat,
  getMessages,
  selectAssistantChat,
  sendMessage,
} from "./store/assistantSlice";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import MessageRowSkeleton from "./MessageRowSkeleton";
import { selectCurrentSuite } from "src/app/main/testcase/store/suiteSlice";
import { openTestDialog } from "src/app/main/testcase/store/testsSlice";
import AiIcon from "./AiIcon";
import { selectTestCaseTypes } from "src/app/main/testcase/store/testSlice";

const StyledMessageRow = styled("div")(({ theme }) => ({
  "&.assistant": {
    "& .bubble": {
      //   backgroundColor: theme.palette.primary.main,
      //   color: theme.palette.secondary.contrastText,
      borderTopLeftRadius: 5,
      borderBottomLeftRadius: 5,
      borderTopRightRadius: 20,
      borderBottomRightRadius: 20,
      "& .time": {
        marginLeft: 12,
      },
    },
    "&.first-of-group": {
      marginTop: 20,
      "& .bubble": {
        borderTopLeftRadius: 20,
      },
    },
    "&.last-of-group": {
      "& .bubble": {
        borderBottomLeftRadius: 20,
      },
    },
  },
  "&.user": {
    paddingLeft: 20,

    "& .bubble": {
      marginLeft: "auto",
      backgroundColor: theme.palette.background.default,
      //   color: theme.palette.primary.contrastText,
      borderTopLeftRadius: 20,
      borderBottomLeftRadius: 20,
      borderTopRightRadius: 5,
      borderBottomRightRadius: 5,
      "& .time": {
        justifyContent: "flex-end",
        right: 0,
        marginRight: 12,
      },
    },
    "&.first-of-group": {
      "& .bubble": {
        borderTopRightRadius: 20,
      },
    },

    "&.last-of-group": {
      "& .bubble": {
        borderBottomRightRadius: 20,
      },
    },
  },
  "&.first-of-group": {
    "& .bubble": {
      borderTopLeftRadius: 20,
      paddingTop: 13,
    },
  },
  "&.last-of-group": {
    "& .bubble": {
      borderBottomLeftRadius: 20,
      paddingBottom: 13,
      "& .time": {
        display: "flex",
      },
    },
  },
}));

const handleAssistantMessages = (message) => {

  const jsonMessage = JSON.parse(message);
  if ("testcase" in jsonMessage) {
    return jsonMessage.testcase;
  } else if ('saved_test_case_ids' in jsonMessage) {
    const labels = Object.keys(jsonMessage);
    const testcase_ids = jsonMessage[labels[1]].join(', ')
    return testcase_ids
  } else {
    const labels = Object.keys(jsonMessage);
    return jsonMessage[labels[1]];
  }
};

const parseJsonToHtml = (json) => {
  return Object.keys(json).reduce((html, param) => {
    if (param !== 'scenario') {
      const capitalized = param.charAt(0).toUpperCase() + param.slice(1);
      html += `<p><strong>${capitalized}</strong> ${json[param]}</p>`;
    }
    return html;
  }, "");
};


const AIChat = (props) => {
  const chatScroll = useRef(null);
  const [messageText, setMessageText] = useState("");
  const dispatch = useDispatch();
  let chat = useSelector(selectAssistantChat);
  const [loading, setLoading] = useState(false);
  const suite = useSelector(selectCurrentSuite);
  const testcaseTypes = useSelector(selectTestCaseTypes);

  useEffect(() => {
    scrollToBottom();
  }, [chat]);

  useEffect(() => {
    setLoading(true);

    dispatch(getMessages(suite.id)).then((res) => {
      setLoading(false);
    });

    return () => {
      dispatch(clearChat());

    }
  }, []);

  function scrollToBottom() {
    chatScroll.current.scrollTo({
      top: chatScroll.current.scrollHeight,
      behavior: "smooth",
    });
  }

  const onInputChange = (ev) => {
    setMessageText(ev.target.value);
  };

  const openTestCaseDialog = (message) => {
    dispatch(openTestDialog({
      testId: null, open: true, test: {
        splitters: [],
        linked_issues: [],
        summary: message.scenario,
        description: parseJsonToHtml(message),
        template_id: _.find(testcaseTypes, ["name", "BDD"])?.id,
        linked_issues: [],
        suite: {
          "id": suite?.id,
        }
      }
    }))

  }

  return (
    <Paper
      className={clsx("flex flex-col relative pb-64 shadow", props.className)}
      sx={{ background: (theme) => theme.palette.background.white }}
    >
      <div
        ref={chatScroll}
        className="flex flex-1 flex-col overflow-y-auto overscroll-contain"
        option={{ suppressScrollX: true, wheelPropagation: false }}
      >
        <div className="flex flex-col">
          {useMemo(() => {
            function isFirstMessageOfGroup(item, i) {
              return (
                i === 0 ||
                (chat[i - 1] &&
                  chat[i - 1].consversation_id !== item.consversation_id)
              );
            }

            return chat.map((item, i) => {
              return (
                <StyledMessageRow
                  key={i}
                  className={clsx(
                    "flex bubble gap-10 grow-0 shrink-0  px-10 pb-10 items-start justify-start relative",
                    item.role === "user" ? "user" : "assistant",
                    { "first-of-group": isFirstMessageOfGroup(item, i) }
                    // { "last-of-group": isLastMessageOfGroup(item, i) }
                  )}
                >
                  {item.role != "user" && <AiIcon size="26" />}

                  <div
                    className={clsx(
                      "flex relative items-center justify-center max-w-full py-5 px-10",
                      { bubble: item.role == "user" }
                    )}
                  >
                    <div className="leading-relaxed ">
                      {item.role === "assistant"
                        ? (() => {
                          const messages = handleAssistantMessages(
                            item.message
                          );
                          if (Array.isArray(messages)) {
                            return messages.map((message, i) => (
                              <div
                                key={i}
                                className="hover:cursor-pointer mb-5 py-5 px-10 rounded-4 dark:text-white dark:bg-dark-green bg-gray-100"
                              >
                                {Object.keys(message).map((param) => (
                                  <div key={param} className="mb-5">
                                    <strong className="capitalize">
                                      {param}
                                    </strong>{" "}
                                    {message[param]}
                                  </div>
                                ))}
                                <div className="flex justify-end">
                                  <IconButton onClick={() => openTestCaseDialog(message)}>
                                    <FuseSvgIcon size={18}>feather:edit</FuseSvgIcon>
                                  </IconButton>
                                </div>
                              </div>
                            ));
                          } else {
                            return messages;
                          }
                        })()
                        : item.message}
                    </div>
                  </div>
                </StyledMessageRow>
              );
            });
          }, [chat])}
          {loading && <MessageRowSkeleton />}
        </div>
      </div>

      {useMemo(() => {
        const onKeyDown = (e) => {
          if (!loading && e.code == "Enter") {
            onMessageSubmit(e);
          }
        };

        const onMessageSubmit = (ev) => {
          setLoading(true);
          ev.preventDefault();
          if (messageText === "") {
            return;
          }
          dispatch(
            addChatMessage({
              role: "user",
              message: messageText,
            })
          );
          setMessageText("");
          dispatch(
            sendMessage({
              message: messageText,
              conversation_id: suite.id,
              role: "user",
            })
          ).then(() => setLoading(false));
        };

        return (
          <>
            <form
              onSubmit={onMessageSubmit}
              className="pb-16 px-8 absolute bottom-0 left-0 right-0"
            >
              <Paper
                className="rounded-24 flex items-center relative shadow"
                sx={{ background: (theme) => theme.palette.background.default }}
              >
                <InputBase
                  autoFocus={false}
                  multiline={true}
                  id="message-input"
                  onKeyDown={onKeyDown}
                  className="flex leading-relaxed flex-1 grow shrink-0 mx-16 ltr:mr-48 rtl:ml-48 my-8"
                  placeholder="Type your message"
                  onChange={onInputChange}
                  value={messageText}
                />

                <IconButton
                  className="absolute ltr:right-0 rtl:left-0 top-0"
                  disabled={loading}
                  onClick={onMessageSubmit}
                  type="button"
                  size="large"
                >
                  <FuseSvgIcon color="action">material-solid:send</FuseSvgIcon>
                </IconButton>
              </Paper>
            </form>
          </>
        );
      }, [chat, dispatch, messageText])}
    </Paper>
  );
};

export default AIChat;
