import React, { useEffect, useRef, forwardRef } from "react";
import EditorJS from "@editorjs/editorjs";
// import Checklist from "@editorjs/checklist";
// import Table from "editorjs-table";
import Header from '@editorjs/header';
import NestedList from '@editorjs/nested-list';
import { create } from "lodash";

const createEditor = (props) => {
    const { memoizedHolder, placeholder, editorJS, value, onEditorChange } = props;
    return new EditorJS({
        holder: memoizedHolder.current,
        placeholder: placeholder,
        data: value,
        tools: {
            header: {
                class: Header,
                inlineToolbar: true,
                config: {
                    placeholder: 'Enter a header',
                    levels: [2, 3, 4],
                    defaultLevel: 3
                }
            },
            list: {
                class: NestedList,
                inlineToolbar: true,
                config: {
                    defaultStyle: 'unordered'
                },
            },

        },
        onChange: (api, event) => {
            editorJS.current
                .save()
                .then((outputData) => {
                    onEditorChange(outputData); // Propagate changes up to the form
                })
                .catch((error) => {
                    console.log("EditorJS: error", error);
                    console.error("EditorJs: Sdaving failed: ", error);
                });
        },
    });
};

const TamborEditor = forwardRef((props, ref = null) => {
    const {
        defaultValue,
        value,
        placeholder,
        onEditorChange,
        holder,
        loading = false,
    } = props;
    const memoizedHolder = useRef(
        holder ?? `react-editor-js-${Date.now().toString(16)}`
    );
    const editorJS = useRef(ref);

    useEffect(() => {
        editorJS.current = createEditor({
            memoizedHolder,
            editorJS,
            placeholder,
            onEditorChange,
            value,
        });

        return () => {
            console.log('destroyed Editor')
        }
    }, [])



    // useEffect(() => {
    //     if (loading) {
    //         editorJS.current?.destroy();
    //     } else {
    //         editorJS.current = createEditor({
    //             memoizedHolder,
    //             editorJS,
    //             placeholder,
    //             value,
    //             onEditorChange
    //         });
    //     }
    // }, [loading]);

    // useEffect(() => {
    //     editorJS.current = createEditor({
    //       memoizedHolder,
    //       editorJS,
    //       placeholder,
    //       value,
    //     });

    //     return () => {
    //         editorJS.current?.destroy();
    //     };
    // }, []);

    // useEffect(() => {
    //     if (value) {
    //         editorJS.current?.isReady.then(() => {
    //             editorJS.current.render({ blocks: value.blocks });
    //         });
    //     }
    // }, [value]);

    return <div className="tambora-editor" id={memoizedHolder.current} />;
});

export default TamborEditor;
