import {
  TabContext,
  TabList,
  TabPanel,
  TimelineConnector,
  TimelineContent,
  TimelineDot,
  TimelineSeparator,
  Timeline,
} from "@mui/lab";
import TimelineItem, { timelineItemClasses } from "@mui/lab/TimelineItem";
import { Tab, Typography } from "@mui/material";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { getTestResults, selectTestResults } from "../../../store/testSlice";
import { dateFormatter } from "../../../../../utils/Formatter";
import ResultEmptyState from "./ResultEmptyState";
import FuseLoading from "@fuse/core/FuseLoading";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import TestResultTimeLine from "./TestResultTimeLine";

export const TestResults = ({ testId }) => {
  const testResults = useSelector(selectTestResults);
  const [value, setValue] = useState("0");



  const handleChange = (event, newValue) => {
    setValue(newValue);
  };



  const setColor = (color) => ` text-${color}-800`;
  const setBgColor = (color) => `bg-${color}-800`;

  return (
    <TabContext value={value}>
      <TabList
        sx={{ borderBottom: 1, borderColor: "divider" }}
        textColor="secondary"
        indicatorColor="secondary"
      >
        <Tab label="Status History" value="0" />
      </TabList>

      <TabPanel className="p-0 pb-32" value={value}>
        <div>
          <TestResultTimeLine testId={testId} />
        </div>
      </TabPanel>
    </TabContext>
  );
};

export default TestResults;
