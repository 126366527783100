import { lazy } from 'react';
import { authRoles } from 'src/app/auth';
import { SelectSuiteMessage } from '../testcase/content/SelectSuiteMessage';
import TestRunResultContent from './testrun-process/testcases/TestRunResultContent';
import TestRunProcessApp from './testrun-process/TestRunProcessApp';
import SelectTestContent from './testrun/select-tests/SelectTestContent';
import TestRun from './testrun/TestRun';
import TestRunResults from './testrun-process/testcases/TestRunResults';

const TestRuns = lazy(() => import('./testruns/TestRuns'));

const TestRunAppConfig = {
    settings: {
        layout: {},
    },
    routes: [
        {
            path: 'project/:projectKey/testruns',
            element: <TestRuns />,
        },
        {
            path: 'project/:projectKey/testruns/new',
            auth: [...authRoles.external_tester],
            element: <TestRun />,
        },
        {
            path: 'project/:projectKey/testruns/clone',
            element: <TestRun />,
        },
        {
            path: 'project/:projectKey/testruns/:testRunKey/edit',
            element: <TestRun />,
        },
        {
            path: 'project/:projectKey/testruns/:testRunKey/suites',
            auth: [...authRoles.external_tester],
            element: <TestRunProcessApp />,
            children: [
                {
                    path: '',
                    element: <SelectSuiteMessage importButton={false} />
                },
                {
                    path: ':suiteId',
                    element: <TestRunResultContent />,
                },

            ]
        },
        {
            path: 'project/:projectKey/testruns/:testRunKey/summary',
            element: <TestRunProcessApp />,
        },
    ],
};

export default TestRunAppConfig;
