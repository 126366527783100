import React, { useEffect } from "react";
import { styled } from "@mui/material/styles";
import SuiteTreeView from "src/app/main/testcase/sidebar/SuiteTreeView";
import FuseLoading from "@fuse/core/FuseLoading";
import FusePageSimple from "@fuse/core/FusePageSimple";
import { useSelector } from "react-redux";
import { selectTest, setSelectedTest } from "../../store/testRunProcessSlice";
import { Outlet, useParams } from "react-router-dom";
import TestRightSidebar from "./TestRightSidebar";
import FuseUtils from "@fuse/utils/FuseUtils";
import { useDispatch } from "react-redux";

const Root = styled(FusePageSimple)(({ theme }) => ({
  "& .FusePageSimple-header": {
    backgroundColor: theme.palette.background.paper,
    borderBottomWidth: 1,
    borderStyle: "solid",
    borderColor: theme.palette.divider,
    overFlow: "auto",
  },
}));




const TestRunResults = (props) => {
  const { loading } = props;
  const { projectKey, testRunKey } = useParams();
  const selectedTest = useSelector(selectTest)
  const dispatch = useDispatch();

  useEffect(() => {
    return (() => {
      dispatch(setSelectedTest({}));
    })
  }, [])

  return (
    <Root
      header={undefined}
      content={<Outlet />}
      leftSidebarOpen={true}
      leftsidebarwidth={500}
      leftSidebarOnClose={() => {
        setLeftSidebarOpen(false);
      }}
      leftSidebarContent={
        loading ? (
          <FuseLoading />
        ) : (
          <SuiteTreeView
            droppable={false}
            actionButton={false}
            urlPath={`/project/${projectKey}/testruns/${testRunKey}`}
          />
        )
      }
      rightSidebarContent={<TestRightSidebar />}
      rightSidebarWidth={450}
      rightSidebarOpen={!FuseUtils.isObjectEmpty(selectedTest)}
      scroll="content"
    />
  );
};

export default TestRunResults;
