import React from "react";
import TestRunResults from "./testcases/TestRunResults";
import TestRunProcessSummary from "./summary/TestRunProcessSummary";

const TestRunProcessContent = (props) => {
  const { selectedTab, loading } = props;

  if (selectedTab == "summary") {
    return <TestRunProcessSummary />;
  }

  if (selectedTab == "suites") {
    return <TestRunResults loading={loading} />;
  }
};

export default TestRunProcessContent;
